import { createStore } from "vuex";
import connections from "@/modules/connections/store";
import auth from "@/modules/auth/store";

const store = createStore({
    modules: {
        connections, auth
    }
});

export default store;