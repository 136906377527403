const FetcherLogList = () => import(
    /* webpackChunkName: "FetcherLogList" */
    '@/modules/fetcher-logs/views/ListView.vue'
);


export default [
    {
        path: '/fetcher-logs',
        name: "Logs de fetcher",
        component: FetcherLogList,
        meta: {
            in_menu: true,
            icon: 'fas fa-file',
            requiresAuth: true
        }
    }
]