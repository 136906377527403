import { getCurrentInstance, reactive, ref, watch } from 'vue';

export default function useVFKeyValue(props, emit) {
    const keyValues = ref(props.defaultValue || {});
    const nombre = ref('');
    const valor = ref('');
    const errors = reactive({});
    const instance = getCurrentInstance();
    const keyForbidden = ["_id", "created_at", "updated_at"];
    const onAdd = () => {
        const isValid = onCheckValues();

        if (!isValid) return;
        keyValues.value[nombre.value] = valor.value;

        onClearValues();
    };

    const onRemove = (key) => {
        if (key in keyValues.value) {
            delete keyValues.value[ key ];
            if (props.NotificationRemove) {
                onShowToast('Se elimino correctamente', 'vf-success');
            }
        }
    };

    const onReset = () => {
        keyValues.value = {};
    };

    const onCheckValues = () => {
        onClearErrors();
        let success = true;

        if (!nombre.value) {
            errors.nombre = 'Debe ingresar una clave';
            success = false;
        }

        if (!valor.value) {
            errors.valor = 'Debe ingresar un valor';
            success = false;
        }

        return success;
    };

    const onClearValues = () => {
        nombre.value = '';
        valor.value = '';
    };

    const onClearErrors = () => {
        errors.nombre = '';
        errors.valor = '';
    }

    const onShowToast = (message, className) => {
        instance.appContext.config.globalProperties.$toast.success(message, {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: false,
            draggable: true,
            showCloseButtonOnHover: true,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            toastClassName: className
        });
    };

    watch(
        () => keyValues.value,
        () => emit('newdata', keyValues.value),
        { deep: true }
    );

    const onAddMassive = (data) => {
        if (typeof data !== 'object') {
            return false;
        }
        for (const key in data) {
            if (keyForbidden.indexOf(key) < 0) {
                keyValues.value[key] = data[key];
            }
        }
    };

    return {
        keyValues,
        nombre,
        valor,
        errors,
        onAdd,
        onRemove,
        onReset,
        onAddMassive
    };
}
