const ConnectionsList = () => import(
    /* webpackChunkName: "ConnectionsListView" */
    '@/modules/connections/views/ListView.vue'
);
const ConnectionsCreate = () => import(
    /* webpackChunkName: "ConnectionsCreate" */
    '@/modules/connections/views/CreateView.vue'
);
const ConnectionsEdit = () => import(
    /* webpackChunkName: "ConnectionsEdit" */
    '@/modules/connections/views/UpdateView.vue'
);
const ConnectionsView = () => import(
    /* webpackChunkName: "ConnectionsDataView" */
    '@/modules/connections/views/DataView.vue'
);
const ConnectionsFileFetchingEdit = () => import(
    /* webpackChunkName: "ConnectionUpdateFileFetchingConfigurationView" */
    '@/modules/connections/views/UpdateFileFetchingConfigurationView.vue'
);
export default [
    {
        path: '/connections',
        name: 'Conexiones',
        component: ConnectionsList,
        meta: {
            in_menu: true,
            icon: 'fas fa-project-diagram',
            requiresAuth: true
        }
    },
    {
        path: '/connections/create',
        name: 'conexion-crear',
        component: ConnectionsCreate,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/connections/edit/:id',
        name: 'conexion-editar',
        component: ConnectionsEdit,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/connections/edit-file-fetching/:id',
        name: "conexion-archivos-editar",
        component: ConnectionsFileFetchingEdit,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        },
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/connections/view/:id',
        name: 'conexion-ver',
        component: ConnectionsView,
        props: ( route ) => {
            return {
                id: route.params.id
            }
        },
        meta: {
            requiresAuth: true
        }
    }
]
