import { routingRuleApi } from "@/api";

/**
 *
 */
export const login = async ( { commit }, dataForm) => {
    try {
        let result = {
            ok: false,
            message: 'El usuario o la contraseña son incorrectos '
        }

        const { data } = await routingRuleApi.post('/api/login', dataForm);

        if (data.ok) {
            result.ok = true;
            result.message = " Usuario Correcto ";
            result.data = data;
            commit('setToken', data.token);
        }

        return result;
    } catch (error) {
        let message = 'El usuario o la contraseña son incorrectos ';
        return {
            ok: false,
            message
        }
    }
}