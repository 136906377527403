export const getConnectionsData = ( data ) => {
    return data.map( connection => {
        const { _id, from, to, action, topic } = connection;
        return { _id, from, to, action, topic };
    });
}

export const getConnectionsErrorCode = ( error ) => {
    let code = error.response?.status ?? 500;

    if (error.response?.data?.message?.includes('401 Unauthorized')) {
        code = 401;
    }
    return code;
}

export const getConnectionsMessageError = ( error, defaultMessage ) => {
    if (error.response?.data?.message) {
        return error.response.data.message;
    }
    return defaultMessage;
}
