import { createRouter, createWebHistory } from 'vue-router';
import routeConnections from '@/modules/connections/router';
import fetcherLogsRouter from '@/modules/fetcher-logs/router';

const Login = () => import('@/modules/auth/views/LoginView.vue');
const Home = () => import('@/views/HomeView.vue');

const routes = [
    {
        path: '/',
        name: "Login",
        component: Login,
        meta: {
            in_menu: false
        }
    },
    {
        path: '/home',
        name: "Inicio",
        component: Home,
        meta: {
            in_menu: true,
            icon: 'fas fa-home',
            requiresAuth: true
        }
    },
    ...routeConnections,
    ...fetcherLogsRouter
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem("EXC-Token") === null && to.query.token === null && to.query.redirect_data === null) {
        next({
          path: "/",
          params: { nextUrl: to.fullPath },
        });
      } else {
        next();
      }
    } else {
      next();
    }
  });


export default router;
