export const getToken = ( state ) => {
    if (!state.token) {
        return localStorage.getItem("EXC-Token");
    }
    return state.token;
};

export const getHeaders = ( state ) => {
    return state.config;
};
